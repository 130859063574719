import axios from '@/axios'
const route = 'system/role/permission'
export default{
    getAllRulePemission(ItemsPerPage,page,search,roleId){
        return  axios.get(route+'/get_all?page='+page+'&items_per_page='+ItemsPerPage+'&search='+search+'&role_id='+roleId)
    },

    attachOrDetach(permission,role){
          return axios.post(route+'/attach_or_detach',{permission:permission,role:role})
    },

    AllAttachOrDetach(permissions,role){
        return axios.post(route+'/all_attach_or_detach',{permissions:permissions,role:role})
  }

    // store(role){
    //     return axios.post(route,role)
    // },

    // update(role){
    //     return axios.put(route + '/' + role.id ,role)
    // },

    // destroy(role){
    //     return axios.delete(route+ '/' + role.id)
    // },

}
