<template>
  <v-container>

    <v-data-table
       dense
      :loading="loading_data_table"
      :headers="headers"
      :items="desserts"
      sort-by="corporate_name"
      :pageCount="numberOfPagesPaginate"
      :page="currentPagePaginate"
      :server-items-length="totalStagesPaginate"
      :options.sync="options"
      class="elevation-1"
      disable-pagination ='true'
      hide-default-footer='true'
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Permissões</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
            <v-row>
              <v-col cols='10'>
                <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              @click:append="searchItems()"
              label="Localizar"
              @change="searchItems()"
              single-line
              hide-details
              class="mr-8"
          ></v-text-field>
              </v-col>
              <v-col >
                <v-switch hide-details label="Todos" @click="switchAll()"  v-model="allSwitch"   ></v-switch>
              </v-col>
            </v-row>



        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
      <v-row>
        <v-col offset="9" cols='3' class="text-right">

            <v-switch hide-details  v-if="!(getRole.deletable =='not' && item.dir =='system.role') " v-model="item.switch"  @click="editItem(item)" ></v-switch>
          </v-col>
      </v-row>


      </template>
      <template v-slot:no-data> Nenhum registro encontrado </template>
    </v-data-table>
  <!-- snackbar -->
  <v-snackbar
      v-model="snackbar"
      :color="snackbarColor"
    >
      {{textSnackbar}}
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>


<script>
import service from "./service";
import { mapGetters } from "vuex";
export default {
  data: () => ({
    allSwitch:false,
    snackbarColor:'success',
    textSnackbar:null,
    snackbar:false,
    totalStagesPaginate : 0,
    numberOfPagesPaginate: 0,
    currentPagePaginate:0,
    options: {},
    btnLoadingSave:false,
    loading_data_table: false,
    dialog: false,
    dialogDelete: false,
    search:'',
    headers: [
      { text: "CÓDIGO", align: "start", sortable: false, value: "id" },
      { text: "NOME", value: "name"   },
      { text: "PERMISSÃO",  align: "right", value: "actions", sortable: false,  },
    ],
    desserts: [],
    editedIndex: -1,
    editedItem: {
      id: "",
      code: "",
      name: "",
    },
    defaultItem: {
      id: "",
      code: "",
      name: "",
    },
  }),

  computed: {
    ...mapGetters('Role',['getRole']),

  },

  watch: {
    options: {
      handler() {
        this.initialize();
      },
    },
    deep: true,

  },

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      this.loading_data_table = true;
      let roleId = this.getRole.id
      const { page, itemsPerPage } = this.options;

      service.getAllRulePemission(itemsPerPage||10 ,page ,this.search, roleId).then((response) => {
        this.desserts = this.addPropertySwitch(response.data)
        this.totalStagesPaginate = response.data.total
        this.numberOfPagesPaginate = response.data.last_page
        this.currentPagePaginate = response.data.current_page
        this.loading_data_table = false;
      });
    },

    searchItems(){
      this.options = {}
      this.initialize()
    },

    editItem(item) {
      service.attachOrDetach(item,this.getRole).then(()=>{
        this.textSnackbar = "Salvo com sucesso"
        this.snackbarColor = 'success'
        this.snackbar = true;
      }).catch(()=>{
        this.textSnackbar = "Não foi possivel salvar"
        this.snackbarColor = 'error'
        this.snackbar = true;
      })

    },
    switchAll(){
      let key = false
      if(this.allSwitch){
        key = true
      }
      this.desserts.forEach((item)=>{

        if(!(this.getRole.deletable =='not' && item.dir =='system.role')){
            item.switch = key
        }

      })


      service.AllAttachOrDetach(this.desserts,this.getRole).then(()=>{
        this.textSnackbar = "Salvo com sucesso"
        this.snackbarColor = 'success'
        this.snackbar = true;
      }).catch(()=>{
        this.textSnackbar = "Não foi possivel salvar"
        this.snackbarColor = 'error'
        this.snackbar = true;
      })
    },
    addPropertySwitch(data){
        data.forEach(item => {
            if(item.where_role.length == 0){
                item.switch = false;
            }else{
                item.switch = true;
            }
        });

        return data;
    }

  },
};
</script>
